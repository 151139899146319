<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CCard v-if="channel">
        <CCardHeader>
          <div class="header d-flex align-items-center justify-content-between">
            <div class="header__title">
              <strong
                >{{ $t("channel.channel") }} {{ channel.code_name }}</strong
              >
              <span class="subtitle ml-1"
                >({{ $t("channel.configurationVersion") }}:
                {{ channel.config_version }})</span
              >
            </div>
            <div class="header__action">
              <CButton color="gray" size="sm" @click.prevent="openSignTransactionModal">
                <CIcon name="cil-fingerprint" />
                {{ $t("channel.signTrans") }}
              </CButton>

              <ChannelImportConfiguration
                :channelId="channelId"
                :show="showImportModal"
                @closeModal="closeImportModal"
              />

              <CButton
                :disabled="!canChange"
                color="gray"
                size="sm"
                @click.prevent="goToDraft"
              >
                <CIcon name="cil-pencil" /> {{ $t("channel.changeChannel") }}
              </CButton>
              <CButton
                color="gray"
                size="sm"
                @click="openImportModal"
                :disabled="!canChange"
              >
                <CIcon name="cil-cloud-upload" />
                {{ $t("channel.importConfiguration") }}
              </CButton>
              <CButton color="gray" size="sm" @click.prevent="downloadConf">
                <CIcon name="cil-cloud-download" /> {{ $t("channel.download") }}
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow class="object-card-row">
            <CCol sm="3" class="font-weight-bold">{{
              $t("channel.bchNet")
            }}</CCol>
            <CCol sm="9">
              <span class="ml-2">{{ channel.blockchain_net_name }}</span>
            </CCol>
          </CRow>

          <CRow class="object-card-row">
            <CCol sm="3" class="font-weight-bold">{{
              $t("channel.policyChangeState")
            }}</CCol>
            <CCol sm="9">
              <span class="ml-2">{{ policyLabel }}</span>
            </CCol>
          </CRow>

          <CRow class="object-card-row">
            <CCol sm="3" class="font-weight-bold">{{
              $t("channel.policyLifeCycle")
            }}</CCol>
            <CCol sm="9">
              <span class="ml-2">{{ lifeCyclePolicyLabel }}</span>
            </CCol>
          </CRow>

          <CRow class="object-card-row">
            <CCol sm="3" class="font-weight-bold mb-2">
              {{ $t("channel.participants") }}
            </CCol>
            <CCol sm="9">
              <ChannelsObjectsCollapse
                :net-id="channel.blockchain_net_id"
                :items="channel.participants"
                :hosts="extraHosts"
                :isForChannels="true"
                :channelAdminId="channel.channel_administrator_org_id"
                :chaincodeAdminId="channel.chaincode_administrator_org_id"
                itemNameAttr="mspid"
                objectType="organization_peer"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <SignTransactionModal
      v-if="channel"
      :name="channel.code_name"
      :isShow="showSignTransactionModal"
      @onClose="closeSignTransactionModal"
    />
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";

import moment from "moment";

import ChannelsObjectsCollapse from "../channels/ChannelsObjectsCollapse.vue";
import utilMessages from "../../../utils/messages";
import { ChannelConfigUpdateType } from "./../common/transaction/constants";
import { saveFile, createFileNameWithDate } from "@/utils/system";
import SignTransactionModal from "../common/SignTransaction";
import ChannelImportConfiguration from "./ChannelImportConfiguration.vue";

export default {
  name: "Channel",
  components: {
    SignTransactionModal,
    ChannelsObjectsCollapse,
    ChannelImportConfiguration,
  },
  data() {
    return {
      channelId: null,
      channel: null,
      org_orderer_accordion: null,
      showImportModal: false,
      showSignTransactionModal: false
    };
  },

  async mounted() {
    if (this.$route.params.id) {
      this.channelId = this.$route.params.id;

      await this.getChannel();
      await this.fetchHosts();
    }

    await this.fetchEndpoints();
  },

  computed: {
    ...mapGetters(["extraHosts", "getUser"]),
    policyLabel() {
      const type =
        ChannelConfigUpdateType[this.channel.channel_update_policy_type];
      return type ? type.text : "";
    },
    lifeCyclePolicyLabel() {
      const type =
        ChannelConfigUpdateType[this.channel.lifecycle_update_policy_type];
      return type ? type.text : "";
    },
    canChange() {
      switch (this.channel.channel_update_policy_type) {
        case ChannelConfigUpdateType.OR.code:
        case ChannelConfigUpdateType.MAJORITY.code:
          return true;
        case ChannelConfigUpdateType.EXCLUSIVE.code:
          return (
            this.getUser.org_id === this.channel.channel_administrator_org_id
          );
        default:
          return false;
      }
    },
  },

  methods: {
    async getChannel() {
      try {
        this.channel = await this.$store.dispatch(
          "fetchChannel",
          this.channelId
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    getDateFormatted(dt) {
      return moment(dt).format("DD.MM.YYYY HH:mm");
    },
    getTypeLabel(type) {
      switch (type) {
        case "hlf-1.4":
          return "Hyperledger Fabric 1.4 (ECDSA)";
        case "hlf-2.0":
          return "Hyperledger Fabric 2.0 (ECDSA)";
        case "gosmart":
          return "GoSmart (ГОСТ)";
      }
    },
    goToDraft() {
      this.$router.push({ path: `/channels/${this.channel.id}/draft` });
    },
    async downloadConf() {
      try {
        const result = await this.$store.dispatch(
          "exportChannel",
          this.channel
        );
        const content = JSON.stringify(result);
        const name = createFileNameWithDate(result.code_name);
        saveFile(content, name);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async fetchHosts() {
      if (!this.$store.getters.getExtraHosts(this.channel.blockchain_net_id)) {
        try {
          await this.$store.dispatch(
            "fetchExtraHosts",
            this.channel.blockchain_net_id
          );
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }
    },
    async fetchEndpoints() {
      try {
        await this.$store.dispatch("fetchEndpoints");
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    openImportModal() {
      this.showImportModal = true;
    },
    closeImportModal() {
      this.showImportModal = false;
    },
    closeSignTransactionModal() {
      this.showSignTransactionModal = false;
    },
    openSignTransactionModal() {
      this.showSignTransactionModal = true;
    }
  },
};
</script>
<style>
.object-card-row {
  margin-bottom: 20px;
}

.object-card-row div {
}

.collapse_item_header {
  cursor: pointer;
}

.collapse_item_header.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) calc(0.25rem - 1px)
    calc(0.25rem - 1px);
}

.subtitle {
  color: #979595;
}
</style>
