/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import i18n from '../../i18n'

export const validationMessages = {
  'required': i18n.t('common.messages[0]'),
  'codeName': i18n.t('common.messages[1]'),
  'MSPID': i18n.t('common.messages[2]'),
  'hostname': i18n.t('common.messages[3]'),
  'domain': i18n.t('common.messages[4]'),
  'port': i18n.t('common.messages[5]'),
}

export default {
  validation: validationMessages
}
