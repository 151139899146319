<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div>
    <CLink @click='certsCollapse = !certsCollapse' color='primary' class='d-block mb-1'>
      {{ $t('channel.certificates') }}
      <fa-icon icon='angle-down' size='sm' v-if='!certsCollapse' class='ml-1' />
      <fa-icon icon='angle-up' size='sm' v-if='certsCollapse' class='ml-1' />
    </CLink>
    <CCollapse :show='certsCollapse' :duration='400'>
      <CCard body-wrapper>
        <div class='row mb-3'>
          <strong class='col-sm-3'>{{ $t('channel.ucca') }}</strong>
          <span class='col-sm-9'>
            <certificate v-for='(certId, index) in org.msp[certTypes.cacerts.code]' :org-id='org.id'
              :type-of='certTypes.cacerts.code' :cert-id='certId' :text-if-is='true' :key="index"></certificate>
          </span>
        </div>

        <div class='row mb-3' v-for='(certId, i) in org.msp[certTypes.intermediatecerts.code]' :key="i">
          <strong class='col-sm-3'>{{ $t('channel.intermediateCa') }} {{ (i + 1) }}</strong>
          <span class='col-sm-9'>
            <certificate :org-id='org.id' :type-of='certTypes.intermediatecerts.code' :cert-id='certId'
              :text-if-is='true'></certificate>
          </span>
        </div>

        <div class='row mb-3'>
          <strong class='col-sm-3'>{{ $t('channel.tlsCa') }}</strong>
          <span class='col-sm-9'>
            <certificate v-for='(certId, index) in org.msp[certTypes.tlscacerts.code]' :org-id='org.id'
              :type-of='certTypes.tlscacerts.code' :cert-id='certId' :text-if-is='true' :key="index"></certificate>
          </span>
        </div>

        <div class='row mb-3' v-for='(certId, i) in org.msp[certTypes.tlsintermediatecerts.code]' :key="i">
          <strong class='col-sm-3'>{{ $t('channel.tlsInter') }} {{ (i + 1) }}</strong>
          <span class='col-sm-9'>
            <certificate :org-id='org.id' :type-of='certTypes.tlsintermediatecerts.code' :cert-id='certId'
              :text-if-is='true'></certificate>
          </span>
        </div>

        <div class='row mb-3' v-for='(certId, i) in org.msp[certTypes.admincerts.code]' :key="i">
          <strong class='col-sm-3'>{{ $t('channel.admin') }} {{ (i + 1) }}</strong>
          <span class='col-sm-9'>
            <certificate :org-id='org.id' :type-of='certTypes.admincerts.code' :cert-id='certId' :text-if-is='true'>
            </certificate>
          </span>
        </div>

      </CCard>
    </CCollapse>

  </div>
</template>

<script>
import Certificate from '../certificates/Certificate';
import { CertificateTypes } from '../certificates/constants';

export default {
  name: 'ChannelOrg',
  components: {
    Certificate
  },
  data() {
    return {
      certTypes: CertificateTypes,

      certsCollapse: true
    };
  },
  props: {
    netId: {
      type: String,
      required: true
    },
    org: {
      type: Object,
      required: true
    }
  },
};
</script>
