<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->
<template>
  <CModal
    :title="$t('channel.signTransConfig')"
    :show="isShow"
    :closeOnBackdrop="false"
    :closeButton="false"
    @close="closeModal"
    size="xl"
  >
    <CCard>
      <CCardBody class="my-test">
        <div class="loading-overlay" v-if="isSigning">
          <div class="d-flex align-items-center justify-content-center">
            <fa-icon
              icon="sync"
              size="1x"
              class="animated mr-2"
              style="color: black"
            />
            <span class="gray-text"> {{ $t("common.processing") }}... </span>
          </div>
        </div>

        <CSelect
          v-if="fabricAdminToolList.length > 0"
          label="Fabric-admin-tool*"
          addLabelClasses="font-weight-bold"
          :options="fabricAdminToolList"
          :value.sync="form.fabricAdminTool"
          :isValid="isValid.fabricAdminTool"
          :tooltipFeedback="false"
          :invalidFeedback="validationMessages.required"
          horizontal
          required
          placeholder=" "
          @change="$v.form.fabricAdminTool.$touch()"
        />

        <div role="group" class="form-group form-row">
          <label class="col-form-label col-sm-3 font-weight-bold">{{
            $t("channel.selectFile")
          }}</label>
          <div class="col-sm-9">
            <b-form-file
              v-model="form.file"
              :placeholder="$t('channel.selectFilePl')"
              lang="ru"
              class="mb-3"
              required
              @change="fileChange"
              :browse-text="$t('common.browse')"
            ></b-form-file>
          </div>
        </div>

        <transaction-diff
          v-if="transaction"
          :oldVal="transaction.oldConfig"
          :newVal="transaction.newConfig"
        >
        </transaction-diff>
        <div
          v-else
          class="diff-placeholder d-flex align-items-center justify-content-center"
        >
          <span v-if="!fileLoading" class="gray-text">
            {{ $t("channel.uploadFileInFiledAbove") }}
          </span>
          <div
            v-else="fileLoading"
            class="d-flex align-items-center justify-content-center"
          >
            <fa-icon
              icon="sync"
              size="1x"
              class="animated mr-2"
              style="color: black"
            />
            <span class="gray-text"> {{ $t("common.processing") }}... </span>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <div slot="footer" class="footer-buttons">
      <CButton
        type="button"
        class="btn btn-secondary mr-1"
        :disabled="isSigning"
        @click="closeModal"
      >
        {{ $t("common.cancel") }}
      </CButton>
      <CButton
        :disabled="!canSign || isSigning"
        type="button"
        class="btn btn-primary"
        @click="signChangeTransaction"
      >
        {{ $t("common.sign") }}
      </CButton>
    </div>
  </CModal>
</template>

<script>
import { BFormFile } from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

import utilMessages from "@/utils/messages";
import messages from "@/views/hauberk/messages";
import TransactionDiff from "./TransactionDiff";

export default {
  name: "SignTransactionModal",
  components: {
    BFormFile,
    TransactionDiff,
  },
  props: {
    name: String,
    isShow: Boolean
  },
  data() {
    return {
      validationMessages: messages.validation,
      fabricAdminToolList: [],
      form: {
        file: null,
        fabricAdminTool: null,
      },
      transaction: null,
      fileLoading: false,
      transactionId: null,
      isSigning: false,
    };
  },
  validations: {
    form: {
      fabricAdminTool: {
        required,
      },
      file: {
        required,
      },
    },
  },
  async mounted() {
    await this.getFabricAdminTool();
  },
  computed: {
    isValid() {
      let fabricAdminTool;

      if (this.$v.form.fabricAdminTool.$dirty) {
        !this.$v.form.fabricAdminTool.$invalid;
      }

      return {
        fabricAdminTool,
      };
    },
    canSign() {
      return (
        !this.$v.form.fabricAdminTool.$invalid && !this.$v.form.file.$invalid
      );
    },
  },
  methods: {
    ...mapActions(["signTransaction"]),
    fileChange($event) {
      this.fileLoading = true;
      this.form.file = $event.target.files[0];

      if (!this.form.file) {
        this.fileLoading = false;
        this.transaction = null;
        return;
      }

      var reader = new FileReader();
      reader.readAsText(this.form.file, "UTF-8");
      reader.onload = this.showDiff;
    },
    showDiff({ target }) {
      try {
        const {
          old_config_json: oldConfig,
          new_config_json: newConfig,
          id: transactionId,
        } = JSON.parse(target.result);

        if (!newConfig || !oldConfig) {
          this.$toast.error(this.$i18n.t("channel.invalidTransactionFile"));
          this.transaction = null;
          this.form.file = null;
          return;
        }

        this.transaction = {
          oldConfig,
          newConfig,
        };
        this.transactionId = transactionId;
      } catch {
        this.$toast.error(this.$i18n.t("channel.invalidTransactionFile"));
        this.transaction = null;
        this.form.file = null;
      } finally {
        this.fileLoading = false;
      }
    },
    async signChangeTransaction() {
      try {
        this.isSigning = true;
        const { file } = this.form;
        const base64File = await this.readFileAsBase64(file);

        const payload = {
          data: base64File,
          admin_tool_endpoint_id: this.form.fabricAdminTool,
          peer_endpoint_id: null,
          transaction_id: this.transactionId,
        };

        const jsonData = await this.signTransaction(payload);
        if (!jsonData) {
          return;
        }

        const name = `${this.name}-${this.getCurrentDateTime()}.json`;
        this.saveFile(name, jsonData);

        this.$toast.success(this.$i18n.t("channel.transSigned"));
        this.closeModal();
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      } finally {
        this.isSigning = false;
      }
    },
    closeModal() {
      this.$v.$reset();
      this.form = {};
      this.transaction = null;
      this.$emit('onClose');
    },
    async getFabricAdminTool() {
      const param = `status=attached&current_org=true&type=fabric-admin-tools`;
      const fabricList = await this.$store.dispatch("fetchEndpoints", param);

      if (fabricList.length > 0) {
        this.fabricAdminToolList = fabricList.map((item) => {
          return {
            label: item.hostname,
            value: item.id,
          };
        });
      }
    },
    saveFile(fileName, fileContent) {
      const data = window.atob(fileContent);
      const jsonBlob = new Blob([data], { type: "application/javascript" });

      const a = document.createElement("a");
      a.setAttribute("style", "display: none");
      const url = window.URL.createObjectURL(jsonBlob);
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
    readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }

          resolve(encoded);
        };
        reader.onerror = (error) => reject(error);
      });
    },
    getCurrentDateTime() {
      var today = new Date();
      var date = `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}`;
      var time = `${today.getHours()}-${today.getMinutes()}`;
      return `${date}_${time}`;
    },
  },
};
</script>
<style scoped>
.gray-text {
  color: #768192;
}

.diff-placeholder {
  height: 70px;
  border: 1px dashed #dedede;
}

.my-test {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;

  background-color: rgba(0, 0, 0, 0.2);
}
</style>
