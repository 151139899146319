<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div>
    <CCard
      v-for="(org, i) in items"
      :accentColor="accordion === i ? 'primary': ''"
      class="mb-2"
      v-bind:key="org.mspid"
    >
      <CCardHeader
        class="border-bottom-0 collapse-item__header"
        @click="accordion = accordion === i ? false : i"
      >
        <strong>{{ org[itemNameAttr] }}</strong>

        <CBadge class="mx-2" color="primary" v-if="org.id === channelAdminId">
          {{ $t('channel.adminBadge') }}
        </CBadge>
        <CBadge class="mx-2" color="primary" v-if="org.id === chaincodeAdminId">
          {{ $t('channel.chaincodeAdminBadge') }}
        </CBadge>

        <div class="card-header-actions">
          <CButton size="sm" class="p-0 m-0" >
            <fa-icon icon="angle-up" size="lg" v-if="accordion === i" />
            <fa-icon icon="angle-down" size="lg" v-if="accordion !== i" />
          </CButton>
        </div>
      </CCardHeader>
      <CCollapse :show="accordion === i">
        <CCardBody class="border-top" v-if="hosts && accordion === i">
          <ChannelOrg
            v-if="objectType == 'organization_orderer' || objectType == 'organization_peer'"
            :net-id="netId"
            :org="org"
          />
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import ChannelOrg from "../channels/ChannelOrg.vue";

export default {
  name: 'ObjectsCollapse',

  data() {
    return {
      accordion: false
    }
  },

  components: {
    ChannelOrg
  },

  props: {
    netId: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    hosts: {
      type: Object,
    },
    itemNameAttr: {
      type: String,
      default: 'name',
    },
    objectType: String,
    isForChannels: {
      type: Boolean,
      default: false,
    },
    channelAdminId: String,
    chaincodeAdminId: String
  },

  computed: {
  },

  methods: {
  },

  watch: {
  }
};
</script>

<style type="css">
.collapse-item__header {
  cursor: pointer;
}
</style>